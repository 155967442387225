import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            src={mobileAbout}
            w="100%"
            pv="5px"
            alt="Moltan Sushi About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px">
          <CFImage src={about} w="90%" alt="About" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}

import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { logo, hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <CFView w="100%" column center mt="15px">
            <CFImage src={logo} w="75%" alt="Promotions" />
          </CFView>
          <CFView textCenter column center w="100%" ph="7%" pt="10px" pb="5px">
            <CFView column center>
              <CFText color="white" h1 raleway bold></CFText>
            </CFView>
            <CFView mt="5px" mb="10px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          maxWidth="1400px"
        >
          <CFView w="100%" column justifyStart ml="5%" mt="2%">
            <CFImage src={logo} w="50%" maxWidth="260px" alt="Promotions" />
          </CFView>
          <CFView
            row
            center
            alignCenter
            ph="65px"
            bg="rgb(198,0,0,.6)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column justifyStart>
              <CFText
                color="white"
                style={{
                  fontSize: 28,
                }}
                raleway
                bold
              >
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView pt="5px" ml="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
